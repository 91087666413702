@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900|Poppins:300,400,500,600,700,800,900&display=swap);

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6d7881;
    --gray-dark: #495057;
    --primary: #23afe6;
    --secondary: #6d7881;
    --success: #0ae06b;
    --info: #002a4d;
    --warning: #f7b917;
    --danger: #d22f38;
    --light: #f8f9fa;
    --dark: #191e1f;
    --accent: #f0e306;
    --conceptual: #005499;
    --uplifting: #942fea;
    --legend: #fb7c1c;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #1799cc !important;
    border-color: #1690c0 !important;
}

/*COOKIE BAR */
.consent-set-selected { color: #007eff; }
@media only screen and (max-width: 415px) {
    .modal-title { padding-bottom: 0px !important;}
    #contact_message { height: 100px !important; }
    #consentModal .modal-body .btn.btn-primary { margin-left: 10px; padding: 20px 10px; }
    #consentModal .modal-body { padding-left: 0px; padding-right: 0px;}
}
@media (min-width: 767px) {
    #consentModal .modal-body a { margin-left: 30px; }
    #consentModal .modal-body .btn-primary { margin-left: 65px; }
}

@media (max-width: 767px) {
    #consentModal .modal-body a { margin-left: 10px; }
    #consentModal .modal-body .btn-primary { margin-left: 20px; }
}

/* End cookie bar */

body form[name="fos_user_resetting_form"] ul { border-color: #FF4D4C; list-style-type: none; padding-bottom: 10px; color: #FF4D4C; }

/* Hide reCAPTCHA badge */
.grecaptcha-badge { visibility: hidden; }

/* pretty checkbox */
.material-switch { padding-left: 5px; min-width: 60px; }
.material-switch label { cursor: pointer; display: inline; }
.material-switch > div { position: relative; margin-top: 3px; }
.material-switch > div  input[type="checkbox"] { display: none; }
.material-switch > div  label { text-align: left; cursor: pointer; float: left; margin-bottom: 15px; position: relative; padding-left: 60px; }
.material-switch > div  label::before, .material-switch > div  label::before {  background: rgb(0, 0, 0);  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);  border-radius: 8px; content: '';
    height: 16px; margin-top: -8px; position:absolute; opacity: 0.3; transition: all 0.4s ease-in-out; width: 40px; top: 33px; left: 0px; z-index: 1; }
.material-switch > div  label::after, .material-switch > div  label::after {  z-index: 1; }
.material-switch > div  label span { position: relative; top: -4px; margin-bottom: 2px; }
.material-switch > div  input[type="checkbox"]:focus, .material-switch > div  input[type="radio"]:focus { outline: 0; }
.material-switch > div  input[type="checkbox"]:focus::after, .material-switch > div  input[type="radio"]:focus::after { outline: 0; }
.material-switch > div  label::after, .material-switch > div  label::after {  background: rgb(255, 255, 255);  border-radius: 16px; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); content: '';
    height: 24px; left: -4px; margin-top: -8px; position: absolute; top: 29px; transition: all 0.3s ease-in-out; width: 24px; }
.material-switch > div  input[type="checkbox"]:checked + label::before, .material-switch > div  input[type="radio"]:checked + label::before { background: inherit; opacity: 1; background-color: #cee1e8; }
.material-switch > div  input[type="checkbox"]:checked + label::after, .material-switch > div  input[type="radio"]:checked + label::after { background: inherit; left: 20px; background-color: #2684ff; }
/* end pretty checkbox */

/* PRETTY RADIO */
.form-check { position: relative; }
.form-check [type=radio]:checked, .form-check [type=radio]:not(:checked), .form-check [type=checkbox]:checked, .form-check [type=checkbox]:not(:checked) { position: absolute; opacity: 0; }
.form-check input[type=radio], .form-check input[type=checkbox] { box-sizing: border-box; padding: 0; margin-top: .3rem; margin-left: -1.25rem; }
.form-check-input[type=radio]:checked+span, .form-check-input[type=radio]:not(:checked)+span,
.form-check-input[type=checkbox]:checked+span, .form-check-input[type=checkbox]:not(:checked)+span{
    position: relative; padding-left: 30px; cursor: pointer; display: inline-block; height: 1.5625rem; line-height: 1.5625rem;
    -webkit-transition: .28s ease; -o-transition: .28s ease; transition: .28s ease; -webkit-user-select: none;-moz-user-select: none; -ms-user-select: none; user-select: none;
}
.form-check-input[type=radio]:checked+span:after,
.form-check-input[type=checkbox]:checked+span:after { -webkit-transform: scale(1.02); -ms-transform: scale(1.02); transform: scale(1.02); background-color: var(--primary); border: 2px solid var(--primary);}
.form-check-input[type=radio]:not(:checked)+span:after, .form-check-input[type=radio]:not(:checked)+span:before,
.form-check-input[type=checkbox]:not(:checked)+span:after, .form-check-input[type=checkbox]:not(:checked)+span:before{ border: 2px solid #5a5a5a; }
.form-check-input[type=radio]:checked+span:before, .form-check-input[type=checkbox]:checked+span:before { border: 2px solid transparent; }
.form-check-input[type=radio]:not(:checked)+span:after, .form-check-input[type=checkbox]:not(:checked)+span:after { -webkit-transform: scale(0); -ms-transform: scale(0); transform: scale(0); }
.form-check-input[type=radio]:checked+span:after, .form-check-input[type=radio]:checked+span:before,
.form-check-input[type=radio]:not(:checked)+span:after, .form-check-input[type=radio]:not(:checked)+span:before { -webkit-border-radius: 50%; border-radius: 50%; top: 0px; }
.form-check-input[type=checkbox]:checked+span:after, .form-check-input[type=checkbox]:checked+span:before,
.form-check-input[type=checkbox]:not(:checked)+span:after, .form-check-input[type=checkbox]:not(:checked)+span:before{
    -webkit-border-radius: 20%; border-radius: 20%; top: 0px; color: white; content: "\f00c"; font-family: "Font Awesome 6 Pro"; padding-left: 1px; font-size: 10px; line-height: 13px;
}
.form-check-input[type=radio]+span:after, .form-check-input[type=radio]+span:before,
.form-check-input[type=checkbox]+span:after, .form-check-input[type=checkbox]+span:before{
    content: ''; position: absolute; left: 0; top: 0; margin: 4px; width: 16px; height: 16px; z-index: 0; -webkit-transition: .28s ease; -o-transition: .28s ease; transition: .28s ease;
}
.form-check-input:disabled+span { color: #6c757d; }
.form-check-input:disabled, .form-check-input:disabled+span { pointer-events: none!important; cursor: default !important; }
.form-check-input[type=radio]:disabled:checked+span:before, .form-check-input[type=radio]:disabled:not(:checked)+span:before,
.form-check-input[type=checkbox]:disabled:checked+span:before, .form-check-input[type=checkbox]:disabled:not(:checked)+span:before{
    background-color: transparent;
    border-color: rgba(0,0,0,.46);
}
.form-check-input[type=radio]:checked+span:after, .form-check-input[type=checkbox]:checked+span:after { background-color: var(--primary); border: 2px solid var(--primary); }
/* END PRETTY RADIO */

/* PRICING */
@media only screen and (max-width: 992px) {
    #slider-fullwidth-container-price, #slider-fullwidth-container-price .container { padding-left: 0px; padding-right: 0px; }
    #slider-fullwidth-container-price .single-card { margin-left: 10px;}
    #slider-fullwidth-slider-price { padding: 0px;}
    #slider-fullwidth-slider-price .slider { margin-left: 0px; padding-left: 0px;}
    #slider-fullwidth-container-price  #slider-fullwidth-slider-price { position: initial; top: auto; width: auto; }
}

/* CAPI  */
.capi-small { font-size: 23px; }
.capi-header { position: relative; }
.capi-header .sub-heading { position: absolute; bottom: -17px; right: 10px; font-size: 13px; }
ul.capi-list { padding-top: 40px }
ul.capi-list li { color: #001240; font-weight: 300; margin-bottom: 16px; font-size: 16px; text-align: left; padding-left: 30px; }
ul.capi-list li i { margin-right: 10px; }
#vat_id { margin-bottom: 10px;}
#vat_id_2 { margin-top: 3px; border-color: transparent;}
.capi-price { font-size: 30px; font-weight: bold; padding-top: 20px; padding-bottom: 30px; }
.capi-price .small { color: #aaa; font-size: 15px;}
.capi-price-append { text-align: center; font-size: 11px; padding-top: 8px;}
div.capi-demo-block.close-cta { margin-top: -400px;}
div.capi-demo-block h3 { font-size: 35px; }

.loading-ring { width: 24px; height: 24px; position: absolute; right: 8px; display: none; top: 19px; }
.loading-ring:after { content: " "; display: block; width: 18px; height: 18px; margin: 1px; border-radius: 50%; border: 2px solid #fff; border-color: #fff transparent #fff transparent; animation: lds-dual-ring 1.2s linear infinite; }
@keyframes lds-dual-ring { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
.loading-ring-blue { width: 24px; height: 24px; position: absolute; right: 8px; display: none; top: 19px; }
.loading-ring-blue:after { content: " "; display: block; width: 18px; height: 18px; margin: 1px; border-radius: 50%; border: 2px solid var(--primary); border-color: var(--primary) transparent var(--primary) transparent; animation: lds-dual-ring 1.2s linear infinite; }
@keyframes lds-dual-ring { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }

/* API DOCS */
.api-logo { padding-left: 20px}
.docs h3 { font-size: 28px; }
.docs h4 { margin-bottom: 7px; padding-bottom: 0px;}

.docs h4.heading { padding-top: 0px; padding-bottom: 0px; }
.docs .tab-content { min-height: 500px;}
.docs .tab-content, .docs .tab-content table td { font-size: 12px; }
.docs .left-menu { padding-bottom: 100px;}
.docs .left-menu .nav-item .nav-link { color: #93a3b5; border-left: 2px solid #d7d7d7; padding: 3px 15px; font-weight: bold; font-size: 14px; }
.docs .left-menu .nav-item .nav-link:hover { background-color: white; border-left: 2px solid #007EFF; color: #007EFF; }
.docs .left-menu .nav-item .nav-link.active { border-left: 2px solid #007EFF; color: #007EFF; }
.docs .left-menu .nav-item .nav-link:focus { background-color: white; }
.docs .left-menu .nav-item .nav-link i.fa-sort-down { transform: rotate(-90deg); position: relative; left: -3px; }
.docs .left-menu .nav-item .nav-link i.fa-dot-circle { font-size: 10px; position: relative; top: -1px; margin-right: 3px; }
.docs .left-menu .nav-item .nav-link.sub-link { padding-left: 25px; ; font-weight: normal; font-size: 13px; }
.docs .left-menu .nav-item.active .nav-link { border-left: 2px solid #007EFF; color: #007EFF; }
.docs .left-menu .nav-item .nav-link.disabled { cursor: default; }
.docs .left-menu .nav-item .nav-link.disabled:hover { background-color: white; border-left: 2px solid #d7d7d7; color: #93a3b5; }
.docs .left-menu .nav-item.active .nav-link.disabled { border-left: 2px solid #d7d7d7; color: #93a3b5; }
.docs small, .sqzlabel { margin-left: 10px; font-weight: 500; color: white; background-color: var(--primary); border-radius: 3px; padding: 3px 8px; position: relative; top: -2px; }
.sqzlabel { margin-left: 0px; }
.docs .alert {
    color: #555;
    background-color: #f9f9f9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .23);
    padding: 15px 15px 15px 40px;
    font-size: 15px;
    border: 0px solid #ccc;
    border-left-width: 10px;
    list-style-type: none;
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 20px;
}
.docs .alert.alert-danger, .docs .alert-light.alert-danger { border-color: #FF4D4C; }
.docs .alert.alert-success, .docs .alert-light.alert-success { border-color: var(--green); }
.docs .alert.alert-warning, .docs .alert-light.alert-warning { border-color: var(--orange); }
.docs .alert b, .docs .alert-light b { font-size: 14px; padding-bottom: 3px; display: inline-block; }
.docs .alert .alert-light .fa-solid, .docs .alert-light .fa-solid, .docs .alert-light .fa-regular { top: -1px; padding-right: 3px; font-size: 30px; }
.docs .alert.alert-danger .fa-solid, .docs .alert-light.alert-danger .fa-solid { color: #FF4D4C; }
.docs .alert.alert-success .fa-solid, .docs .alert-light.alert-success .fa-solid { color: var(--green); }
.docs .nav-link { padding: 0px; display: inline; }
.docs .badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #777;
    border-radius: 10px;
}


/* login */
#login_screen { background-color: white; margin-bottom: 30px; border-radius: 3px; border: 1px solid #e9eaec; padding: 40px; border-bottom-width: 2px; position: relative; text-align: left; }
#login_screen label.form-check { margin-bottom: 10px; padding-left: 0px; }
#remember_me { margin-bottom: 18px; }
.forgot-pass { margin-top: 0px; margin-bottom: 15px; }
.forgot-pass a { color: var(--primary); }
.forgot-pass a:hover { color: #137fa9;
    text-decoration: underline; }
@media only screen and (max-width: 767px) {
    #login_screen { padding: 40px; margin: 40px 20px 100px; }
}
@media only screen and (max-width: 410px) {
    .forgot-pass { float: none !important; margin-top: -5px; margin-bottom: 10px; }
}
#twofa_screen label.form-check { border: 0px; }
/* end login */


/* DEMO PAGE */

.demo-area h6 {
    font-weight: 300
}

.cal{
    text-align: center;
    line-height: 30px
}
.btn-cal {
    background: #C0EFD4;
    -moz-user-select: none;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-family: "Airbnb Cereal App small", sans-serif;
    font-size: 16px;
    font-weight: 100;
    line-height: 0;
    text-align: center;
    padding: 20px 34px;
    border-radius: 5px;
    cursor: pointer;
    transition: color .4s linear;
    position: relative;
    z-index: 1;
    border: 0;
    margin: 0 0 10px;
}
@media only screen and (max-width: 767px) {
    .btn-cal {
        margin: 0 0 10px;
    }
}
.btn-cal:hover { color: black !important;}
.btn-cal::before { background-color: #C0EFD4 !important; }
.btn-cal:hover::before { background-color: #73e4a3 !important; border-radius: 30px; }

.btn-cal.disabled{
    background: #F2B7CF;
    pointer-events: none;
    cursor: default;
}

.past{
    background: #EFEFEF;
    cursor: default;
    pointer-events: none;
}
.btn-cal.disabled::before { background: #F2B7CF !important; cursor: default; }
.btn-cal.past::before { background: #EFEFEF !important; cursor: default; }

.btn-cal::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ef3c86;
    z-index: 1;
    border-radius: 5px;
    transition: transform .5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(.5, 1.6, .4, .7);
    transform: scaleX(0)
}

.btn-cal:hover::before {
    transform: scaleX(1);
    color: #fff!important;
    z-index: -1
}

.demo-area .radius-btn {
    overflow: hidden;
    padding: 18px 23px;
    border-radius: 30px
}

.icon-usp {
    position: absolute;
    top: 10px;
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}


@media(max-width: 555px) {
    .btn-cal {
        width: 100%;
    }
}

body .demo-area {
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 200px;
    background-size: 800px;
    /*padding-top: 160px*/
}

@media(max-width: 992px) {
    body .demo-area {
        /*padding-top: 60px*/
    }
    .endtime { display: none;}
}

.modal .modal-header { border-top-left-radius: .3rem; border-top-right-radius: .3rem; border-bottom-width: 0px; }
.modal .modal-header #contact_date_timeslot { font-family: "Airbnb Cereal App Medium"; margin-left: 20px; font-size: 18px; font-weight: normal; border: 2px solid #2ECC71; background-color: #C0EFD4; padding: 14px 20px; border-radius: 30px; }
.modal .modal-header #contact_date_text { font-size: 30px; margin-left: 20px; position: relative; top: 3px; }
.modal .modal-content { border-radius: 0px; }
.modal .form-control { box-shadow: none; border-radius: 0px; border: 0px solid #001240; border-bottom-width: 1px; }
.modal ::placeholder { color: #011341; opacity: 1; }
.modal :-ms-input-placeholder { color: #011341; }
.modal ::-ms-input-placeholder {  color: #011341; }
.modal .btn.header-radius-btn { width: 100%;}
@media (max-width: 500px) {
    .modal .modal-header #contact_date_timeslot { margin-left: 0px; width: 100%; display: block; text-align: center}
}
@media( min-width : 768px ){
    .modal-dialog.wide { width: 800px; max-width: 100%;  }
    .modal .modal-content .row { margin-bottom: 30px;}
    .modal .modal-content .row:last-of-type { margin-bottom: 10px;}
}
.d-none-normal { display: none;}
@media (min-width: 576px) {
    .d-sm-block-normal {
        display: block;
    }
}

.week-2, .week-3 { display: none}

/* END DEMO PAGE */


h1, h2, h3, h4, h5, h6 { width: 100%;}
.clearer { clear: both; }
header { padding-top: 200px;}
@media only screen and (max-width: 767px) {
    header { padding-top: 80px;}
}
.homepage header, .black header {
    padding-top: 0px;
}
#flash_messages { position: absolute; top: 0px; left: 13px; width: 100%; border-radius: 0px; z-index: 1051;}

main:not(.loggedin) #reset_password_flash_messages #flash_messages {
    position: initial;
}

#flash_messages .alert {
    color: #252525;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    border-radius: 0px;
    border: 0px solid #001240;
}
#flash_messages .alert .fa-solid { margin-right: 15px;}
#flash_messages .alert-environment { background-color: #FF4D4C; border: 0; color: white; border-bottom-left-radius: 2px; border-bottom-right-radius: 2px; }
#flash_messages .alert.alert-success { background-color: #C0EFD4; }
#flash_messages .alert.danger { background-color: #F2B7CF; }

.popup_close svg { cursor: pointer; }
/*.modal-backdrop.in { opacity: .5;}*/
body.modal-open, .modal.in {padding-right: 0px !important; }
.form-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 14px 25px;
    font-size: 16px;
    line-height: 1.428571429;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.white-bg {
    background: #fff
}

.white-bg {
    background: #fff
}

[data-overlay] {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center
}

[data-overlay]::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: ""
}

[data-opacity="1"]::before {
    opacity: .1
}

[data-opacity="2"]::before {
    opacity: .2
}

[data-opacity="3"]::before {
    opacity: .3
}

[data-opacity="4"]::before {
    opacity: .4
}

[data-opacity="5"]::before {
    opacity: .5
}

[data-opacity="6"]::before {
    opacity: .6
}

[data-opacity="7"]::before {
    opacity: .7
}

[data-opacity="8"]::before {
    opacity: .8
}

[data-opacity="9"]::before {
    opacity: .9
}

html {
    scroll-behavior: smooth !important
}

body {
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    font-style: normal
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Airbnb Cereal App Extra Bold", sans-serif;
    color: #001240;
    margin-top: 0;
    font-style: normal;
    font-weight: 500;
}

p {
    font-family: Open Sans, sans-serif;
    color: #001240;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 400;
    z-index: 4
}

.img {
    max-width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.button,
a {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.button:focus,
a:focus {
    text-decoration: none;
    outline: 0
}

a {
    color: #007eff
}
a:hover {
    color: #024fa0
}

header a:hover {
    color: #fff
}

.footer -menu li a:hover,
a:focus,
a:hover {
    text-decoration: none
}

header a,
header button {
    color: #fff;
    outline: medium none
}

button:focus {
    outline: 0
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
}

ul {
    margin: 0;
    padding: 0
}

li {
    list-style: none;
}
section.docs ul {
    margin-top: 0;
    margin-bottom: 10px;
}

ul[type="disc"] li {
    list-style: disc;
    padding-left: 10px;
    margin-left: 20px;
}

hr {
    border-bottom: 1px solid #eceff8;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0
}

.section-padding {
    padding-top: 120px;
    padding-bottom: 120px
}

.mb-90 {
    margin-bottom: 90px
}

@media (max-width: 767px) {
    .mb-90 {
        margin-bottom: 30px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mb-90 {
        margin-bottom: 45px
    }
}

.owl-carousel .owl-nav div {
    background: rgba(255, 255, 255, .8) none repeat scroll 0 0;
    height: 40px;
    left: 20px;
    line-height: 40px;
    font-size: 22px;
    color: #646464;
    opacity: 1;
    visibility: visible;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease 0s;
    width: 40px
}

.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: -30px
}

.owl-carousel .owl-nav div.owl-next i {
    position: relative;
    right: 0;
    top: 1px
}

.owl-carousel .owl-nav div.owl-prev i {
    position: relative;
    right: 1px;
    top: 0
}

.owl-carousel:hover .owl-nav div {
    opacity: 1;
    visibility: visible
}

.owl-carousel:hover .owl-nav div:hover {
    color: #fff;
    background: #ff3500
}

.btn {
    background: #007eff;
    -moz-user-select: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Airbnb Cereal App Medium", sans-serif;
    font-size: 18px;
    font-weight: 100;
    line-height: 0;
    margin-bottom: 0;
    padding: 30px 44px;
    border-radius: 5px;
    cursor: pointer;
    transition: color .4s linear;
    position: relative;
    z-index: 1;
    border: 0;
    overflow: hidden;
    margin: 0
}
.btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ef3c86;
    z-index: 1;
    border-radius: 5px;
    transition: transform .5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(.5, 1.6, .4, .7);
    transform: scaleX(0)
}

.btn:hover::before {
    transform: scaleX(1);
    color: #fff !important;
    z-index: -1
}
.btn-primary.form-control:focus {
    background-color: #2d93f8;
    -moz-user-select: none;
    color: #fff;
}
.btn-danger, .btn-danger:focus { background-color: #FF4D4C ;}

.header-btn {
    background: #4043bc;
    padding: 20px 11px;
    min-width: 120px;
    position: relative
}

.header-btn::before {
    background: #ec4683
}

.card-btn1 {
    background: #712fda;
    border-radius: 6px;
    padding: 30px 40px;
    text-transform: uppercase
}

.card-btn1::before {
    background: #ff374b
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none
}

.radius-btn {
    padding: 28px 33px;
    border-radius: 30px
}

.sticky-bar {
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 5px 15px rgba(25, 25, 25, .1);
    box-shadow: 0 5px 15px rgba(25, 25, 25, .1);
    z-index: 9999;
    -webkit-animation: .3s ease-in-out 0s normal none 1 running fadeInDown;
    animation: .3s ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 5px 15px rgba(25, 25, 25, .1);
    background: #fff
}

.mt-5 {
    margin-top: 5px
}

.mt-10 {
    margin-top: 10px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}

.mt-55 {
    margin-top: 55px
}

.mt-60 {
    margin-top: 60px
}

.mt-65 {
    margin-top: 65px
}

.mt-70 {
    margin-top: 70px
}

.mt-75 {
    margin-top: 75px
}

.mt-80 {
    margin-top: 80px
}

.mt-85 {
    margin-top: 85px
}

.mt-90 {
    margin-top: 90px
}

.mt-95 {
    margin-top: 95px
}

.mt-100 {
    margin-top: 100px
}

.mt-105 {
    margin-top: 105px
}

.mt-110 {
    margin-top: 110px
}

.mt-115 {
    margin-top: 115px
}

.mt-120 {
    margin-top: 120px
}

.mt-125 {
    margin-top: 125px
}

.mt-130 {
    margin-top: 130px
}

.mt-135 {
    margin-top: 135px
}

.mt-140 {
    margin-top: 140px
}

.mt-145 {
    margin-top: 145px
}

.mt-150 {
    margin-top: 150px
}

.mt-155 {
    margin-top: 155px
}

.mt-160 {
    margin-top: 160px
}

.mt-165 {
    margin-top: 165px
}

.mt-170 {
    margin-top: 170px
}

.mt-175 {
    margin-top: 175px
}

.mt-180 {
    margin-top: 180px
}

.mt-185 {
    margin-top: 185px
}

.mt-190 {
    margin-top: 190px
}

.mt-195 {
    margin-top: 195px
}

.mt-200 {
    margin-top: 200px
}

.mb-5 {
    margin-bottom: 5px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-55 {
    margin-bottom: 55px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-65 {
    margin-bottom: 65px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-75 {
    margin-bottom: 75px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-85 {
    margin-bottom: 85px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-95 {
    margin-bottom: 95px
}

.mb-100 {
    margin-bottom: 100px
}

.mb-105 {
    margin-bottom: 105px
}

.mb-110 {
    margin-bottom: 110px
}

.mb-115 {
    margin-bottom: 115px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-125 {
    margin-bottom: 125px
}

.mb-130 {
    margin-bottom: 130px
}

.mb-135 {
    margin-bottom: 135px
}

.mb-140 {
    margin-bottom: 140px
}

.mb-145 {
    margin-bottom: 145px
}

.mb-150 {
    margin-bottom: 150px
}

.mb-155 {
    margin-bottom: 155px
}

.mb-160 {
    margin-bottom: 160px
}

.mb-165 {
    margin-bottom: 165px
}

.mb-170 {
    margin-bottom: 170px
}

.mb-175 {
    margin-bottom: 175px
}

.mb-180 {
    margin-bottom: 180px
}

.mb-185 {
    margin-bottom: 185px
}

.mb-190 {
    margin-bottom: 190px
}

.mb-195 {
    margin-bottom: 195px
}

.mb-200 {
    margin-bottom: 200px
}

.ml-5 {
    margin-left: 5px
}

.ml-10 {
    margin-left: 10px
}

.ml-15 {
    margin-left: 15px
}

.ml-20 {
    margin-left: 20px
}

.ml-25 {
    margin-left: 25px
}

.ml-30 {
    margin-left: 30px
}

.ml-35 {
    margin-left: 35px
}

.ml-40 {
    margin-left: 40px
}

.ml-45 {
    margin-left: 45px
}

.ml-50 {
    margin-left: 50px
}

.ml-55 {
    margin-left: 55px
}

.ml-60 {
    margin-left: 60px
}

.ml-65 {
    margin-left: 65px
}

.ml-70 {
    margin-left: 70px
}

.ml-75 {
    margin-left: 75px
}

.ml-80 {
    margin-left: 80px
}

.ml-85 {
    margin-left: 85px
}

.ml-90 {
    margin-left: 90px
}

.ml-95 {
    margin-left: 95px
}

.ml-100 {
    margin-left: 100px
}

.ml-105 {
    margin-left: 105px
}

.ml-110 {
    margin-left: 110px
}

.ml-115 {
    margin-left: 115px
}

.ml-120 {
    margin-left: 120px
}

.ml-125 {
    margin-left: 125px
}

.ml-130 {
    margin-left: 130px
}

.ml-135 {
    margin-left: 135px
}

.ml-140 {
    margin-left: 140px
}

.ml-145 {
    margin-left: 145px
}

.ml-150 {
    margin-left: 150px
}

.ml-155 {
    margin-left: 155px
}

.ml-160 {
    margin-left: 160px
}

.ml-165 {
    margin-left: 165px
}

.ml-170 {
    margin-left: 170px
}

.ml-175 {
    margin-left: 175px
}

.ml-180 {
    margin-left: 180px
}

.ml-185 {
    margin-left: 185px
}

.ml-190 {
    margin-left: 190px
}

.ml-195 {
    margin-left: 195px
}

.ml-200 {
    margin-left: 200px
}

.mr-5 {
    margin-right: 5px
}

.mr-10 {
    margin-right: 10px
}

.mr-15 {
    margin-right: 15px
}

.mr-20 {
    margin-right: 20px
}

.mr-25 {
    margin-right: 25px
}

.mr-30 {
    margin-right: 30px
}

.mr-35 {
    margin-right: 35px
}

.mr-40 {
    margin-right: 40px
}

.mr-45 {
    margin-right: 45px
}

.mr-50 {
    margin-right: 50px
}

.mr-55 {
    margin-right: 55px
}

.mr-60 {
    margin-right: 60px
}

.mr-65 {
    margin-right: 65px
}

.mr-70 {
    margin-right: 70px
}

.mr-75 {
    margin-right: 75px
}

.mr-80 {
    margin-right: 80px
}

.mr-85 {
    margin-right: 85px
}

.mr-90 {
    margin-right: 90px
}

.mr-95 {
    margin-right: 95px
}

.mr-100 {
    margin-right: 100px
}

.mr-105 {
    margin-right: 105px
}

.mr-110 {
    margin-right: 110px
}

.mr-115 {
    margin-right: 115px
}

.mr-120 {
    margin-right: 120px
}

.mr-125 {
    margin-right: 125px
}

.mr-130 {
    margin-right: 130px
}

.mr-135 {
    margin-right: 135px
}

.mr-140 {
    margin-right: 140px
}

.mr-145 {
    margin-right: 145px
}

.mr-150 {
    margin-right: 150px
}

.mr-155 {
    margin-right: 155px
}

.mr-160 {
    margin-right: 160px
}

.mr-165 {
    margin-right: 165px
}

.mr-170 {
    margin-right: 170px
}

.mr-175 {
    margin-right: 175px
}

.mr-180 {
    margin-right: 180px
}

.mr-185 {
    margin-right: 185px
}

.mr-190 {
    margin-right: 190px
}

.mr-195 {
    margin-right: 195px
}

.mr-200 {
    margin-right: 200px
}

.pt-5 {
    padding-top: 5px
}

.pt-10 {
    padding-top: 10px
}

.pt-15 {
    padding-top: 15px
}

.pt-20 {
    padding-top: 20px
}

.pt-25 {
    padding-top: 25px
}

.pt-30 {
    padding-top: 30px
}

.pt-35 {
    padding-top: 35px
}

.pt-40 {
    padding-top: 40px
}

.pt-45 {
    padding-top: 45px
}

.pt-50 {
    padding-top: 50px
}

.pt-55 {
    padding-top: 55px
}

.pt-60 {
    padding-top: 60px
}

.pt-65 {
    padding-top: 65px
}

.pt-70 {
    padding-top: 70px
}

.pt-75 {
    padding-top: 75px
}

.pt-80 {
    padding-top: 80px
}

.pt-85 {
    padding-top: 85px
}

.pt-90 {
    padding-top: 90px
}

.pt-95 {
    padding-top: 95px
}

.pt-100 {
    padding-top: 100px
}

.pt-105 {
    padding-top: 105px
}

.pt-110 {
    padding-top: 110px
}

.pt-115 {
    padding-top: 115px
}

.pt-120 {
    padding-top: 120px
}

.pt-125 {
    padding-top: 125px
}

.pt-130 {
    padding-top: 130px
}

.pt-135 {
    padding-top: 135px
}

.pt-140 {
    padding-top: 140px
}

.pt-145 {
    padding-top: 145px
}

.pt-150 {
    padding-top: 150px
}

.pt-155 {
    padding-top: 155px
}

.pt-160 {
    padding-top: 160px
}

.pt-165 {
    padding-top: 165px
}

.pt-170 {
    padding-top: 170px
}

.pt-175 {
    padding-top: 175px
}

.pt-180 {
    padding-top: 180px
}

.pt-185 {
    padding-top: 185px
}

.pt-190 {
    padding-top: 190px
}

.pt-195 {
    padding-top: 195px
}

.pt-200 {
    padding-top: 200px
}

.pt-260 {
    padding-top: 260px
}

.pb-5 {
    padding-bottom: 5px
}

.pb-10 {
    padding-bottom: 10px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-25 {
    padding-bottom: 25px
}

.pb-30 {
    padding-bottom: 30px
}

.pb-35 {
    padding-bottom: 35px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-45 {
    padding-bottom: 45px
}

.pb-50 {
    padding-bottom: 50px
}

.pb-55 {
    padding-bottom: 55px
}

.pb-60 {
    padding-bottom: 60px
}

.pb-65 {
    padding-bottom: 65px
}

.pb-70 {
    padding-bottom: 70px
}

.pb-75 {
    padding-bottom: 75px
}

.pb-80 {
    padding-bottom: 80px
}

.pb-85 {
    padding-bottom: 85px
}

.pb-90 {
    padding-bottom: 90px
}

.pb-95 {
    padding-bottom: 95px
}

.pb-100 {
    padding-bottom: 100px
}

.pb-105 {
    padding-bottom: 105px
}

.pb-110 {
    padding-bottom: 110px
}

.pb-115 {
    padding-bottom: 115px
}

.pb-120 {
    padding-bottom: 120px
}

.pb-125 {
    padding-bottom: 125px
}

.pb-130 {
    padding-bottom: 130px
}

.pb-135 {
    padding-bottom: 135px
}

.pb-140 {
    padding-bottom: 140px
}

.pb-145 {
    padding-bottom: 145px
}

.pb-150 {
    padding-bottom: 150px
}

.pb-155 {
    padding-bottom: 155px
}

.pb-160 {
    padding-bottom: 160px
}

.pb-165 {
    padding-bottom: 165px
}

.pb-170 {
    padding-bottom: 170px
}

.pb-175 {
    padding-bottom: 175px
}

.pb-180 {
    padding-bottom: 180px
}

.pb-185 {
    padding-bottom: 185px
}

.pb-190 {
    padding-bottom: 190px
}

.pb-195 {
    padding-bottom: 195px
}

.pb-200 {
    padding-bottom: 200px
}

.pl-5 {
    padding-left: 5px
}

.pl-10 {
    padding-left: 10px
}

.pl-15 {
    padding-left: 15px
}

.pl-20 {
    padding-left: 20px
}

.pl-25 {
    padding-left: 25px
}

.pl-30 {
    padding-left: 30px
}

.pl-35 {
    padding-left: 35px
}

.pl-40 {
    padding-left: 40px
}

.pl-45 {
    padding-left: 45px
}

.pl-50 {
    padding-left: 50px
}

.pl-55 {
    padding-left: 55px
}

.pl-60 {
    padding-left: 60px
}

.pl-65 {
    padding-left: 65px
}

.pl-70 {
    padding-left: 70px
}

.pl-75 {
    padding-left: 75px
}

.pl-80 {
    padding-left: 80px
}

.pl-85 {
    padding-left: 85px
}

.pl-90 {
    padding-left: 90px
}

.pl-95 {
    padding-left: 95px
}

.pl-100 {
    padding-left: 100px
}

.pl-105 {
    padding-left: 105px
}

.pl-110 {
    padding-left: 110px
}

.pl-115 {
    padding-left: 115px
}

.pl-120 {
    padding-left: 120px
}

.pl-125 {
    padding-left: 125px
}

.pl-130 {
    padding-left: 130px
}

.pl-135 {
    padding-left: 135px
}

.pl-140 {
    padding-left: 140px
}

.pl-145 {
    padding-left: 145px
}

.pl-150 {
    padding-left: 150px
}

.pl-155 {
    padding-left: 155px
}

.pl-160 {
    padding-left: 160px
}

.pl-165 {
    padding-left: 165px
}

.pl-170 {
    padding-left: 170px
}

.pl-175 {
    padding-left: 175px
}

.pl-180 {
    padding-left: 180px
}

.pl-185 {
    padding-left: 185px
}

.pl-190 {
    padding-left: 190px
}

.pl-195 {
    padding-left: 195px
}

.pl-200 {
    padding-left: 200px
}

.pr-5 {
    padding-right: 5px
}

.pr-10 {
    padding-right: 10px
}

.pr-15 {
    padding-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pr-25 {
    padding-right: 25px
}

.pr-30 {
    padding-right: 30px
}

.pr-35 {
    padding-right: 35px
}

.pr-40 {
    padding-right: 40px
}

.pr-45 {
    padding-right: 45px
}

.pr-50 {
    padding-right: 50px
}

.pr-55 {
    padding-right: 55px
}

.pr-60 {
    padding-right: 60px
}

.pr-65 {
    padding-right: 65px
}

.pr-70 {
    padding-right: 70px
}

.pr-75 {
    padding-right: 75px
}

.pr-80 {
    padding-right: 80px
}

.pr-85 {
    padding-right: 85px
}

.pr-90 {
    padding-right: 90px
}

.pr-95 {
    padding-right: 95px
}

.pr-100 {
    padding-right: 100px
}

.pr-105 {
    padding-right: 105px
}

.pr-110 {
    padding-right: 110px
}

.pr-115 {
    padding-right: 115px
}

.pr-120 {
    padding-right: 120px
}

.pr-125 {
    padding-right: 125px
}

.pr-130 {
    padding-right: 130px
}

.pr-135 {
    padding-right: 135px
}

.pr-140 {
    padding-right: 140px
}

.pr-145 {
    padding-right: 145px
}

.pr-150 {
    padding-right: 150px
}

.pr-155 {
    padding-right: 155px
}

.pr-160 {
    padding-right: 160px
}

.pr-165 {
    padding-right: 165px
}

.pr-170 {
    padding-right: 170px
}

.pr-175 {
    padding-right: 175px
}

.pr-180 {
    padding-right: 180px
}

.pr-185 {
    padding-right: 185px
}

.pr-190 {
    padding-right: 190px
}

.pr-195 {
    padding-right: 195px
}

.pr-200 {
    padding-right: 200px
}

@-webkit-keyframes float-bob {
    0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
    50% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
}

@-webkit-keyframes heartbeat {
    to {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes zoom {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
}

@keyframes zoom {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
}

.section-padding2 {
    padding-top: 60px;
    padding-bottom: 60px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .section-padding2 {
        padding-top: 60px;
        padding-bottom: 60px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding2 {
        padding-top: 50px;
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding2 {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-padding2 {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

@media (max-width: 767px) {
    .section-padding2 {
        padding-top: 25px;
        padding-bottom: 25px
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .inner-padding {
        /*padding-top: 200px*/
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .inner-padding {
        /*padding-top: 200px*/
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .inner-padding {
        padding-top: 10px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .inner-padding {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .inner-padding {
        padding-top: 30px
    }
}

.section-padding {
    padding-top: 0;
    padding-bottom: 120px
}

.services-padding {
    padding-top: 300px;
    padding-bottom: 150px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .services-padding {
        padding-top: 300px;
        padding-bottom: 150px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .services-padding {
        padding-top: 200px;
        padding-bottom: 150px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .services-padding {
        padding-top: 150px;
        padding-bottom: 70px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .services-padding {
        padding-top: 150px;
        padding-bottom: 70px
    }
}

@media (max-width: 767px) {
    .services-padding {
        padding-top: 150px;
        padding-bottom: 70px
    }
}

.footer-padding {
    padding-top: 130px;
    padding-bottom: 80px;
    background-color: #001240
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .footer-padding {
        padding-top: 130px;
        padding-bottom: 80px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-padding {
        padding-top: 70px;
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-padding {
        padding-top: 70px;
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-padding {
        padding-top: 70px;
        padding-bottom: 50px
    }
}

@media (max-width: 767px) {
    .footer-padding {
        padding-top: 70px;
        padding-bottom: 50px
    }
}

.footer-padding2 {
    padding-top: 100px;
    padding-bottom: 100px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .footer-padding2 {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-padding2 {
        padding-top: 0;
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-padding2 {
        padding-top: 0;
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-padding2 {
        padding-top: 0;
        padding-bottom: 50px
    }
}

@media (max-width: 767px) {
    .footer-padding2 {
        padding-top: 0;
        padding-bottom: 50px
    }
}

.footer-padding3 {
    padding-bottom: 100px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .footer-padding3 {
        padding-bottom: 100px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-padding3 {
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-padding3 {
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-padding3 {
        padding-bottom: 50px
    }
}

@media (max-width: 767px) {
    .footer-padding3 {
        padding-bottom: 50px
    }
}

.white-bg {
    background: #fff
}

.white-bg {
    background: #fff
}

.blog_area a {
    text-decoration: none;
    transition: .4s
}

.blog_area a :hover,
.blog_area a:hover {
    background: -webkit-linear-gradient(131deg, #8f1bdc 0, #8f1bdc 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    transition: .4s
}

.single-blog {
    overflow: hidden;
    margin-bottom: 30px
}

.single-blog:hover {
    box-shadow: 0 10px 20px 0 rgba(42, 34, 123, .1)
}

.single-blog h4 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 34px;
    margin-bottom: 25px
}

.single-blog a {
    font-size: 20px;
    font-weight: 600
}

.single-blog .date {
    color: #666;
    text-align: left;
    display: inline-block;
    font-size: 13px;
    font-weight: 300
}

@media (max-width: 800px) {
    .single-blog {
        margin-bottom: 30px
    }
}

.single-blog .single-blog-content {
    padding: 30px
}

.single-blog .single-blog-content .meta-bottom p {
    font-size: 13px;
    font-weight: 300
}

.single-blog .single-blog-content .meta-bottom i {
    color: #fdcb9e;
    font-size: 13px;
    margin-right: 7px
}

@media (max-width: 1199px) {
    .single-blog .single-blog-content {
        padding: 15px
    }
}

@media (max-width: 1199px) {
    .single-blog h4 {
        transition: all .3s linear 0s;
        border-bottom: 1px solid #dfdfdf;
        padding-bottom: 14px;
        margin-bottom: 12px
    }

    .single-blog h4 a {
        font-size: 18px
    }
}

.l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.l_blog_item .l_blog_text h4 {
    font-size: 18px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    padding-bottom: 20px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.l_blog_item .l_blog_text p {
    margin-bottom: 0;
    padding-top: 20px
}

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media (min-width: 900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.blog_item {
    margin-bottom: 50px
}

.blog-info-link li {
    float: left;
    font-size: 14px
}

.blog-info-link li a {
    color: #999
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px
}

.blog-info-link li:last-child::after {
    display: none
}

.blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.blog_item_img {
    position: relative
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background-color: #8f1bdc;
    padding: 8px 15px;
    border-radius: 5px
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date {
        bottom: -20px;
        left: 40px;
        padding: 13px 30px
    }
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date h3 {
        font-size: 30px
    }
}

.blog_item_img .blog_item_date p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date p {
        font-size: 18px
    }
}

.contact-info {
    margin-bottom: 25px
}

.contact-info__icon {
    margin-right: 20px
}

.contact-info__icon i,
.contact-info__icon span {
    color: #8f9195;
    font-size: 27px
}

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px
}

.contact-section .btn_2 {
    background-color: #191d34;
    padding: 18px 60px;
    border-radius: 50px;
    margin-top: 0
}

.contact-section .btn_2:hover {
    background-color: #222
}

.popup_box {
    background: #fff;
    display: inline-block;
    z-index: 9;
    width: 681px;
    padding: 60px 40px
}

@media (max-width: 767px) {
    .popup_box {
        width: 320px;
        padding: 45px 30px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .popup_box {
        width: 420px !important;
        padding: 45px 30px
    }
}

.popup_box h3 {
    text-align: center;
    font-size: 22px;
    color: #1f1f1f;
    margin-bottom: 46px
}

.flex-center-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start
}

.best-pricing::before {
    background-image: -moz-linear-gradient(0deg, #f9218d 0, #6c19f6 100%);
    background-image: -webkit-linear-gradient(0deg, #f9218d 0, #6c19f6 100%);
    background-image: -ms-linear-gradient(0deg, #f9218d 0, #6c19f6 100%)
}

b {
    color: #222
}

.button-area {
    background: #fff
}

.mt-10 {
    margin-top: 10px
}

.mb-30 {
    margin-bottom: 30px
}

.mt-30 {
    margin-top: 30px
}

.header-transparrent {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9
}

.main-header {
    z-index: 3;
    min-height: 58px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .main-header {
        padding: 21px 0
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .main-header {
        padding: 21px 0
    }
}

@media (max-width: 767px) {
    .main-header {
        padding: 21px 0
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .main-header .main-menu {
        margin-right: 30px
    }
}

.main-header .main-menu ul li {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin: 0 5px
}

.main-header .main-menu ul li > a {
    color: #fff;
    font-family: "Airbnb Cereal App Medium", sans-serif;
    padding: 39px 12px;
    font-weight: 100;
    display: block;
    font-size: 16px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.main-header .main-menu ul.bl li > a {
    color: #001240
}

.main-header .main-menu .bl > a {
    color: #001240
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-header .main-menu ul li > a {
        padding: 39px 5px
    }
}

.main-header .main-menu ul li > a::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 30px;
    background: #fff;
    height: 8px;
    width: 0;
    transition: .3s;
    border-radius: 20px
}

.main-header .main-menu ul li:hover > a::before {
    width: 100%
}

.main-header .main-menu ul li.active > a::before {
    width: 100%
}

.header-area .header-top .header-info-right .header-social a i {
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0)
}

.header-area .header-top .header-info-right .header-social a:hover i {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg)
}

.main-header ul > li:hover > ul.submenu {
    visibility: visible;
    opacity: 1;
    top: 100%
}

.top-bg {
    background: #002d5b
}

.header-sticky .header-info-right ul li a {
    padding: 10px 19px
}

.header-radius-btn {
    padding: 25px 25px !important;
    border-radius: 30px
}

.header-sticky.sticky-bar.sticky .main-menu ul > li > a {
    padding: 39px 12px
}

.header-sticky.sticky-bar.sticky .main-menu ul > li > a::before {
    bottom: 11px
}

.slicknav_menu .slicknav_icon {
    margin-top: 3px;
    position: relative;
    right: 3px;
    top: -5px;
    padding-bottom: 3px
}

.slicknav_menu .slicknav_icon-bar {
    background-color: #fff !important;
    margin-top: 10px;
    border-radius: 10px;
    width: 18px;
    float: right
}

.slicknav_menu .slicknav_icon-bar-2 {
    background-color: #fff !important;
    margin-top: 10px;
    border-radius: 10px;
    height: 3px;
    display: block;
    position: relative;
    top: 9px
}

.slicknav_nav {
    margin-top: 0;
    float: right;
    margin-top: 11px;
    padding: 19px;
    width: 100%;
    border-bottom: 1px solid #eee;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 9999;
    overflow-y: auto
}

.header-sticky.sticky-bar.sticky .header-btn .get-btn {
    padding: 20px 20px
}

.header-area .slicknav_btn {
    top: -40px;
    right: 15px
}

.slicknav_menu .slicknav_nav a {
    color: #001240;
    font-size: 24px;
    font-family: "Airbnb Cereal App Extra Bold", sans-serif;
    padding: 5px 15px;
    margin: 5px 0
}

.slicknav_menu .slicknav_nav a:hover {
    background: 0 0;
    color: #ef3c86
}

.slicknav_menu {
    background: 0 0;
    margin-top: 5px !important
}

.mobile_menu {
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 99
}


.langSelector {
    display: none;
}
.slicknav_menu .slicknav_nav .langSelector {
    display: block;
    position: absolute;
    right: 11px;
    top: 22px;
    color: #fff;
    padding: 5px 10px;
}
.slicknav_menu .slicknav_nav .langSelector a {
    position: relative;
    font-size: 15px;
    display: inline-block;
}

.slider-height {
    min-height: 1000px;
    background-repeat: no-repeat;
    background-position: center center
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .slider-height {
        min-height: 900px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height {
        min-height: 600px
    }
}

@media (max-width: 767px) {
    .slider-height {
        min-height: 600px
    }
}

.bg-blue {
    background: #001240
}

.slider-padding {
    padding-top: 20px
}

@media (max-width: 767px) {
    .slider-padding {
        padding-top: 20px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-padding {
        padding-top: 20px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-padding {
        padding-top: 20px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-padding {
        padding-top: 20px
    }
}

.slider-area {
    margin-top: -1px
}

.slider-area .hero__caption {
    position: relative;
    z-index: 10
}

@media (max-width: 991px) {
    .slider-area .hero__caption {
        top: 117px
    }
}

.slider-area .hero__caption span {
    display: inline-block;
    color: #001240;
    font-size: 16px;
    margin-bottom: 20px
}

.slider-area .hero__caption span a:hover {
    color: #ef3c86
}

.slider-area .hero__caption h1 {
    font-size: 72px;
    color: #fff;
    font-family: "Airbnb Cereal App Bold", sans-serif;
    line-height: 1.3;
    margin-bottom: 32px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .hero__caption h1 {
        font-size: 55px;
        line-height: 1.2
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area .hero__caption h1 {
        font-size: 50px;
        line-height: 1.2
    }
}

@media (max-width: 767px) {
    .slider-area .hero__caption h1 {
        font-size: 39px;
        line-height: 1.4
    }
}

.slider-area .hero__caption p {
    margin-bottom: 54px;
    padding-right: 155px;
    color: #fff
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area .hero__caption p {
        margin-bottom: 54px;
        padding-right: 0
    }
}

@media (max-width: 767px) {
    .slider-area .hero__caption p {
        margin-bottom: 54px;
        padding-right: 0
    }
}

@-webkit-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(83, 16, 90, 0)
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(187, 22, 206, 0)
    }
}

.slider-area .hero__img img {
    position: relative;
    right: -320px;
    z-index: 1;
    width: auto
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .hero__img img {
        left: 400px
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .slider-area .hero__img img {
        left: 300px
    }
}

@media only screen and (max-width: 991px) {
    .slider-area .hero__img img {
        width: auto
    }
}

@media only screen and (max-width: 600px) {
    .slider-area .hero__img img {
        width: 100%;
        margin-top: -250px;
        right: 0
    }
}

.slider-height2 {
    min-height: 300px;
    background-repeat: no-repeat;
    background-position: center center
}

@media (max-width: 767px) {
    .slider-height2 {
        min-height: 260px
    }
}

.services-area {
    padding-top: 270px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .services-area {
        padding-top: 200px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .services-area {
        padding-top: 150px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .services-area {
        padding-top: 150px
    }
}

@media (max-width: 767px) {
    .services-area {
        padding-top: 130px
    }
}

#ab_autopilot_testen, #ab_autopilot_testen .container { padding-bottom: 0px; }
.best-features-area {
    position: relative;

}

.best-features-area .single-features {
    display: flex
}

@media (max-width: 767px) {
    .best-features-area .single-features {
        margin-bottom: 30px
    }
    #ab_autopilot_testen { padding-bottom: 50px; }
}

.best-features-area .single-features .features-icon span {
    position: relative;
    color: #fff;
    font-size: 30px;
    height: 67px;
    width: 67px;
    display: block;
    line-height: 67px;
    border-radius: 50%;
    text-align: center;
    background-image: -moz-linear-gradient(90deg, #e78ae9 0, #926ef8 100%);
    background-image: -webkit-linear-gradient(90deg, #e78ae9 0, #926ef8 100%);
    background-image: -ms-linear-gradient(90deg, #e78ae9 0, #926ef8 100%)
}

.best-features-area .single-features .features-caption {
    padding-left: 19px
}

.best-features-area .single-features .features-caption h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #23182c
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-features-area .single-features .features-caption p {
        font-size: 15px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-features-area .single-features .features-caption p {
        font-size: 15px
    }
}

@media (max-width: 767px) {
    .best-features-area .single-features .features-caption p {
        font-size: 13px
    }
}

.section-tittle h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 90px
}

@media (max-width: 767px) {
    .section-tittle h2 {
        font-size: 30px;
        line-height: 1.3
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-tittle h2 {
        font-size: 40px
    }
}

.content-info {
    overflow: hidden;
    position: relative;
    z-index: 0;
    margin-right: 0;
    margin-left: 0
}

.content-info .single-cases-info {
    position: relative;
    left: 30%;
    margin-top: 72px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .content-info .single-cases-info {
        margin-top: 72px;
        left: 40%
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .content-info .single-cases-info {
        left: 38%;
        margin-top: 0
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .content-info .single-cases-info {
        left: 17%;
        margin-top: 40px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .content-info .single-cases-info {
        left: 1%;
        margin-top: 20px
    }
}

@media (max-width: 767px) {
    .content-info .single-cases-info {
        left: 1%;
        margin-top: 10px
    }
}

.content-info .single-cases-info h3 {
    color: #001240;
    font-size: 50px;
    line-height: 1.3;
    margin-bottom: 20px;
    padding-right: 30px
}

@media (max-width: 767px) {
    .content-info .single-cases-info h3 {
        font-size: 30px
    }
}

.content-info .single-cases-info p {
    margin-bottom: 50px;
    padding-right: 224px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .content-info .single-cases-info p {
        margin-bottom: 20px;
        padding-right: 26px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .content-info .single-cases-info p {
        margin-bottom: 20px;
        padding-right: 26px
    }
}

@media (max-width: 767px) {
    .content-info .single-cases-info p {
        margin-bottom: 20px;
        padding-right: 0
    }
}

.content-info .no-js .owl-carousel {
    overflow: hidden;
    position: relative;
    right: -14%
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .content-info .no-js .owl-carousel {
        right: 0
    }
}

@media (max-width: 767px) {
    .content-info .no-js .owl-carousel {
        right: 0
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .content-info .no-js .owl-carousel {
        right: 0
    }
}

.content-info .owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.section-tittle2 h2 {
    color: #fff
}

.footer-area .footer-logo {
    margin-bottom: 30px
}

.footer-area .footer-logo-2 {
    align-content: center
}

.footer-area .footer-tittle h4 {
    color: #fff;
    font-family: 'Airbnb Cereal App Medium';
    font-size: 18px;
    margin-bottom: 48px;
    font-weight: 100;
    font-variant-caps: all-small-caps;
    padding: 0 0 20px 0;
    margin: 0
}

.footer-area .footer-tittle ul li {
    color: #012f5f;
    margin-bottom: 15px
}

.footer-area .footer-tittle ul li a {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.footer-area .footer-social a {
    width: 40px;
    height: 40px;
    font-size: 14px;
    border: 1px solid #fafafa;
    background: #fafafa;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    color: #949eb2;
    margin-right: 5px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

.footer-area .icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.footer-area .footer-copy-right {
    padding-top: 10px
}

@media (max-width: 767px) {
    .footer-area .footer-copy-right {
        padding-top: 0
    }
}

.footer-area .footer-copy-right p {
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    line-height: 2;
    margin-bottom: 12px
}

.footer-area .footer-copy-right p i {
    color: #f9218d
}

.footer-area .footer-copy-right p a {
    color: #f9218d
}

.footer-area .footer-copy-right p a:hover {
    color: #8f1bdc
}

.footer-social a:hover {
    background: #8f1bdc
}

.footer-social a:hover i {
    color: #fff;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

.hero-caption span {
    color: #fff;
    font-size: 16px;
    display: block;
    margin-bottom: 24px;
    font-weight: 600;
    padding-left: 95px;
    position: relative
}

.hero-caption span::before {
    position: absolute;
    content: "";
    width: 75px;
    height: 3px;
    background: #8f1bdc;
    left: 0;
    top: 52%;
    transform: translateY(-50%)
}

.hero-caption h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700
}

.clients .client-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 120px
}

.clients .client-logo img {
    max-height: 40px
}

.header-sticky.sticky-bar.sticky .main-menu ul > li > a {
    color: #001240
}

@media (max-width: 600px) {
    .logo {
        background-size: 150px 27px;
        width: 150px;
        height: 27px
    }

    .bl .logo {
        background-size: 150px 27px;
        width: 150px;
        height: 27px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .logo {
        background-size: 165px 30px;
        width: 165px;
        height: 30px
    }

    .header-radius-btn {
        padding: 22px 25px !important;
        border-radius: 30px;
        font-size: 14px
    }
}

.bg-block {
    background-color: rgba(244, 247, 254, 1);
    top: 40px;
    height: 100%;
    max-height: 400px;
    width: 400px;
    position: absolute;
    margin: 0;
    left: 180px;
    transform: rotate(0);
    opacity: 1
}

.btn-arrow {
    margin-left: 20px;
    font-family: "Airbnb Cereal App Medium", sans-serif;
    font-size: 18px;
    font-weight: 100
}

.btn-arrow a:hover {
    color: #8f1bdc
}

a.btn-arrow {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .1s ease-out 0s
}

a.btn-arrow-2 {
    color: #007eff;
    margin-left: 0;
    z-index: 4
}

.btn-arrow-2 a:hover {
    color: #fff
}

.header-bottom {
    background-color: #f4f7fe;
    height: 40px
}

.header-bottom .header-info-left ul li {
    display: inline-block;
    padding-left: 10px;
    color: #7f89a0;
    padding-top: 10px;
    font-size: 12px
}

.header-bottom .header-info-left ul li a {
    color: #7f89a0
}

.header-bottom .header-info-left ul li.active a {
    color: #ef3c86
}

.header-top {
    border-bottom-color: #fff;
    padding-top: 8px
}

.header-top .header-info-right {
    float: right;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-family: "Airbnb Cereal App Book", sans-serif;
    font-weight: 100
}

.header-top .header-info-right ul li {
    display: inline-block;
    padding-left: 25px
}

.header-sticky.sticky-bar.sticky .header-top .header-info-right ul li {
    color: #001240
}

.bl .header-top .header-info-right  {
    color: #001240
}

.bl .header-top .header-info-right a {
    color: #001240
}

.bl .main-header .main-menu  ul li > a {
    color: #001240
}


.header-top .header-info-right ul li a {
    color: #fff;
    padding-left: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px
}

.header-sticky.sticky-bar.sticky .header-top .header-info-right ul li a {
    color: #001240
}

.header-top .header-info-right ul li a:hover {
    color: #ef3c86;
    font-weight: 600
}

.header-sticky.sticky-bar.sticky .header-top .header-info-right ul li a:hover {
    color: #ef3c86;
    font-weight: 600
}

.header-top .header-info-right ul li .vacature-link { padding-right: 0px;}
.vacature-amount {
    background-color: #ef3c86;
    color: #fff;
    top: 0;
    height: 100%;
    border-radius: 5px;
    transform: rotate(0);
    opacity: 1;
    font-weight: 100;
    text-align: center;
    padding: 0 9px 0 8px;
    margin: 0 8px 0
}

.header-sticky ul li .language a {
    padding: 0
}

.about {
    padding-bottom: 80px
}

.about .h3,
h3 {
    font-size: 45px;
    padding-bottom: 20px;
    z-index: 4
}

@media (max-width: 600px) {
    .about .h3,
    h3 {
        font-size: 30px
    }
}

.about .h5,
h5 {
    font-family: 'Airbnb Cereal App Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 20px 0 20px;
    padding-left: 55px
}

.about .row .icon-box:hover {
    color: #000;
    -moz-user-select: none;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    border-radius: 35px;
    cursor: pointer;
    transition: color .4s linear;
    position: relative;
    z-index: 1;
    border: 0;
    overflow: hidden;
    margin: 0;
    -webkit-box-shadow: 2px 2px 8px rgba(25, 25, 25, .1);
    box-shadow: 2px 2px 8px rgba(25, 25, 25, .1);
    z-index: 9999;
    -webkit-box-shadow: 2px 2px 8px rgba(25, 25, 25, .1)
}

.about a:hover {
    color: #ef3c86
}

.about a.btn:hover {
    color: #fff
}

.about a.btn-arrow {
    color: #007eff
}

.about a.btn-arrow:hover {
    color: #ef3c86
}

.about .slider-btns {
    padding-top: 20px
}

.about .icon {
    position: absolute;
    top: 10px;
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.best-features-area .container {
    padding: 60px 25px 60px
}

@media (max-width: 600px) {
    .best-features-area .container {
        padding: 30px 25px 00px
    }
}

.feature-info {
    position: relative;
    left: 30%;
    margin-top: 72px
}

.feature-info-left {
    position: relative;
    left: 0;
    margin-top: 20px
}

.feature-info-text {
    position: inherit;
    margin-top: 10px;
    z-index: 4
}

.feature-info-text p {
    padding-left: 0
}

.feature-info-text-left {
    z-index: 4;
    padding-right: 60px
}

.img-fluid {
    margin-top: 50px
}

.img-fluid-feature {
    height: auto;
    margin: -300px
}

.img-fluid-feature-right {
    width: 1000px;
    height: auto;
    margin-left: -130px;
    margin-top: -80px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .img-fluid-feature-right {
        width: 100%;
        margin-left: 0;
        margin-top: 0
    }
}

@media (max-width: 991px) {
    .img-fluid-feature-right {
        margin-left: -15px;
        margin-top: 0;
        max-width: 600px
    }
}

.img-fluid-feature-left {
    max-width: 1000px;
    height: auto;
    margin-left: -250px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .img-fluid-feature-left {
        width: 100%;
        margin-left: 0;
        margin-top: 0
    }
}

@media (max-width: 991px) {
    .img-fluid-feature-left {
        margin-left: -15px;
        margin-top: 0;
        max-width: 600px
    }
}

.feature-info-text .h4,
h4 {
    font-family: 'Airbnb Cereal App bold';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    padding: 20px 0 20px;
    margin-left: 0;
    color: #7f89a0;
    text-transform: uppercase;
    z-index: 5
}

@media (max-width: 1200px) {
    .re-order .row {
        display: flex;
        display: -ms-flex;
        flex-direction: column
    }

    .re-order .row .first {
        order: 1
    }

    .re-order .row .second {
        order: 2
    }
}

.slider {
    width: 100%
}

.slick-slider {
    position: relative;
    display: block
}

.slider_card {
    width: 365px;
    background: #fff;
    box-shadow: 2px 2px 15px 5px rgba(39, 92, 141, .1);
    margin: 0 30px 40px
}

.slick-next,
.slick-next:focus,
.slick-next:hover,
.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
    background: 0 0;
    color: transparent;
}

.slider_text h5 {
    padding: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px

}

.slider_text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    margin-block-start: 0em;
}

.slider_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.slider_img {
    margin-top: 8px;
    position: relative;
    bottom: 2px;
    left: 0;
}

.slick-slider {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: 0;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
    outline: 0;
}

.slick-dots li,
.slick-dots li button {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.slider_report {
    margin-top: 14px;
    max-width: 1400px
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    top: 0;
    left: 0;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}


[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.arrow {
    margin-bottom: 80px;
}

.arrow li {
    list-style: none;
    display: inline-block !important;
    float: left;
}

.arrow ul {
    margin: 0;
    padding: 0;
    margin-top: 24px;
    margin-bottom: 80px;
}

.slick-next:focus,
.slick-prev:focus {
    outline: 0;
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 0;
    cursor: pointer;
    border: none;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
    width: 60px;
    height: 22px;
    margin-top: 5px;
    margin-left: -30px;
    position: relative;
    display: inline-block;
    font-size: 20px;
    line-height: 1;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next:hover,
.slick-prev:hover {
    opacity: 1;
}

.slick-prev {
    opacity: 0.3;
}

.slick-next {
    opacity: 0.3;
}

[dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
}

[dir="rtl"] .slick-prev:before {
    content: "→";
}

[dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
}

[dir="rtl"] .slick-next:before {
    content: "←";
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    background: 0 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #000;
    -moz-osx-font-smoothing: grayscale;
}

.slick-slide img,
.slider {
    width: 100%;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
}

.slider {
    margin: 0px -80px 10px 10px;
    padding-right: 0;
    padding-left: 2rem;
    margin-top: 0;
}

.slick-slide {
    margin: 0px;
    outline: 0;
}

.slick-next:before,
.slick-prev:before {
    color: #000;
}

.slick-current {
    font-size: initial;
}

.item1 .slider_card {
    margin-left: -50px
}

.body_card .slider-btns {
    padding: 20px
}

.use-cases {
    padding-bottom: 20px
}

@media screen and (max-width: 991px) {
    .slider_card {
        margin: 0 !important;
    }

    .arrow li {
        float: none;
    }
}

.container_left {
    display: inline-block
}

.fa-facebook:before {
    color: #007EFF;
    font-size: 20px;
}

.fa-twitter-square:before {
    color: #007EFF;
    font-size: 20px;
    padding-left: 10px
}

text#Plan_een_demo.cls-6 a.btn:hover {
    color: aqua
}

.cta {
    text-align: center;
    z-index: 4;
    top: 100px
}

.cta h3 {
    font-family: "Airbnb Cereal App Bold", sans-serif;
    color: #fff;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 2rem
}

@media screen and (max-width: 1232px) {
    .cta h3 {
        font-size: 43px;
    }
}

.close-cta {
    margin-bottom: -50px;
    padding-top: 135px;
    max-width: 1200px;
    margin-top: -280px
}
.homepage .close-cta { padding-top:0px;}

.cta-text {
    top: 340px;
    z-index: 4;
}

.cta .slider-btns .btn {
    background-color: #fff;
    color: #007EFF
}

.cta .slider-btns .btn:hover {
    color: #fff
}

.mob-menu-bottom ul li {
    color: #001240;
    border-top: 1px;
    font-family: "Airbnb Cereal App Bold", sans-serif;
    line-height: 30px;
    font-size: 16px

}

.mob-menu-bottom ul li a {
    font-family: "Airbnb Cereal App Bold", sans-serif !important;
    line-height: 20px;
    font-size: 16px !important;
    padding-left: 0px !important;
    margin-left: 0px
}

.mob-menu-bottom hr {
    margin-top: 20px;
    margin-bottom: 25px
}

.mob-menu-bottom ul li .language a {
    display: inline-block;
    font-family: "Airbnb Cereal App Book", sans-serif;
    padding: 15px 10px 5px !important;
    margin: 0px
}

.mob-menu-bottom ul li .language a:first-child {
    padding-left: 0px !important;
}

.footer_button {
    position: fixed;
    bottom: 0;
    /*
        width: 100%;
        height: 60px;
        line-height: 60px;
    */
    margin-bottom: 18px;
    z-index: 999

}

.container_button {
    /*
        width: auto;
        max-width: 680px;
    */
    padding: 0 15px;
}

/*slider*/
#slider-fullwidth-container {
    position: relative;
    min-height: 600px;
}

#slider-fullwidth-slider {
    position: absolute;
    top: 0px;
    left: 30%;
    width: 70%;
}

#slider-fullwidth-container .arrow, .arrow ul {
    margin-bottom: 0px;
    margin-top: 0px;
}

#slider-fullwidth-container .slick-arrow {
    background-color: white;
}

#slider-fullwidth-container .slick-prev, #slider-fullwidth-container .slick-next {
    opacity: 1
}

#slider-fullwidth-container #slick-number {
    color: #007EFF;
    font-weight: bold;
    position: relative;
    top: 10px;
    font-size: 18px;
}

@media all and (max-width: 991px) {
    #slider-fullwidth-slider .slider {
        margin-left: 0px;
        padding-left: 0px;
    }

    #slider-fullwidth-slider {
        position: relative !important;
        left: 0%;
        width: 100%;
    }

    #slider-fullwidth-container #slick-number {
        top: 5px;
    }

    div.slider_card {
        margin: 10px !important;
        box-shadow: none;
    }

    div.slider_card img {
        margin-top: 0px;
    }
}

@media all and (max-width: 575px) {
    #slider-fullwidth-container, #slider-fullwidth-slider {
        padding-left: 0px;
        padding-right: 0px;
    }
}


/*slider-price*/
#slider-fullwidth-container-price {
    position: relative;
    min-height: 900px;
}

#slider-fullwidth-slider-price {
    position: absolute;
    top: 0px;
    width: 100%;
}